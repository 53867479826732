.baseContainer {
  
}

.moodSelector {
  width: 100px
}

.bottomBox {
  padding-top: 10px;
}